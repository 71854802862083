import Vue from 'vue'

export default {
  namespaced: true,
  state: {
    item: {},
    items: [],
    itemsTotal: 0,
    stats: {},
    dashboard: {},
  },
  getters: {
    items: state => state.items,
    itemsTotal: state => state.itemsTotal,
    item: state => state.item,
    stats: state => state.stats,
    dashboard: state => state.dashboard,
  },
  mutations: {
    SAVE_ITEMS(state, items) {
      state.items = items
    },
    SAVE_ITEM(state, item) {
      state.item = item
    },
    SAVE_ITEMS_TOTAL(state, itemsTotal) {
      state.itemsTotal = itemsTotal
    },
    CLEAR_ITEMS(state) {
      state.items = []
    },
    CLEAR_ITEM(state) {
      const data = {
        amounts: [],
        personnels: [],
        files_: [],
        host: null,
        active: true,
        publish_in_web: true,
      }

      if (state.item && state.item.type) {
        data.type = state.item.type
      }

      state.item = data
    },
    SAVE_STATS(state, data) {
      state.stats = data
    },
    SAVE_DASHBOARD_DATA(state, data) {
      state.dashboard = data
    },
  },
  actions: {
    async filterDashboard({ commit }, filters) {
      try {
        const resp = await Vue.prototype.$http.post(`${Vue.prototype.$groupUrl}/grants/dashboard`, filters)
        commit('SAVE_DASHBOARD_DATA', resp.data)
      } catch (e) {
        commit('CLEAR_ITEMS')
      }
    },
    async fetchDashboard({ commit }, id = null) {
      try {
        const resp = await Vue.prototype.$http.post(`${Vue.prototype.$groupUrl}/grants/dashboard/${id}`)
        commit('SAVE_DASHBOARD_DATA', resp.data)
      } catch (e) {
        commit('CLEAR_ITEMS')
      }
    },
    async stats({ commit }) {
      try {
        const resp = await Vue.prototype.$http.get(`${Vue.prototype.$groupUrl}/grants/stats`)
        commit('SAVE_STATS', resp.data)
      } catch (e) {
        commit('CLEAR_ITEMS')
      }
    },
    async fetch({ commit }) {
      try {
        const resp = await Vue.prototype.$http.get(`${Vue.prototype.$groupUrl}/grants`)
        commit('SAVE_ITEMS_TOTAL', resp.data.total)
        commit('SAVE_ITEMS', resp.data.data.data)
      } catch (e) {
        commit('CLEAR_ITEMS')
      }
    },
    async filter({ commit }, filters) {
      try {
        const resp = await Vue.prototype.$http.post(`${Vue.prototype.$groupUrl}/grants/filter?min=true`, filters)
        commit('SAVE_ITEMS_TOTAL', resp.data.total)
        commit('SAVE_ITEMS', resp.data.data.data)
      } catch (e) {
        commit('CLEAR_ITEMS')
      }
    },
    async fetchId({ commit }, id) {
      try {
        const resp = await Vue.prototype.$http.get(`${Vue.prototype.$groupUrl}/grants/${id}`)
        resp.data.data.total_amount = resp.data.data.total_amount ? Number.parseFloat(resp.data.data.total_amount.replaceAll(',', '')) : null
        resp.data.data.total_amount_institution = resp.data.data.total_amount_institution ? Number.parseFloat(resp.data.data.total_amount_institution.replaceAll(',', '')) : null
        resp.data.data.total_amount_group = resp.data.data.total_amount_group ? Number.parseFloat(resp.data.data.total_amount_group.replaceAll(',', '')) : null
        commit('SAVE_ITEM', resp.data.data)
      } catch (e) {
        console.log(e)
        throw e
      }
    },
    async update({ commit }, data) {
      try {
        const resp = await Vue.prototype.$http.put(`${Vue.prototype.$groupUrl}/grants/${data.id}`, data.data)
        Vue.prototype.$saveFiles(data.data.files_)
        commit('SAVE_ITEM', resp.data.data)
      } catch (e) {
        console.log(e)
        throw e
      }
    },
    async create({ commit }, data) {
      try {
        const resp = await Vue.prototype.$http.post(`${Vue.prototype.$groupUrl}/grants`, data)
        Vue.prototype.$saveFiles(data.files_)
        commit('SAVE_ITEM', resp.data.data)
      } catch (e) {
        console.log(e)
        throw e
      }
    },
    cleanType({ commit }) {
      commit('CLEAR_ITEM')
    },
    export({ commit }, filters) {
      try {
        Vue.prototype.$export({
          url: `${Vue.prototype.$groupUrl}/grants/export`,
          filters,
          name: 'grants',
          background: true,
        })
      } catch (e) {
        console.log(e)
        commit('FETCH_FAILED')
      }
    },
  },
}
