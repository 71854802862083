import Vue from 'vue'

export default {
  namespaced: true,
  state: {
    item: [],
    itemLabels: [],
    items: [],
    itemsTotal: 0,
    itemTotal: 0,
    itemCategories: [],
    itemMenus: [],
  },
  getters: {
    items: state => state.items,
    itemsTotal: state => state.itemsTotal,
    itemTotal: state => state.itemTotal,
    item: state => state.item,
    itemLabels: state => state.itemLabels,
    filters: state => state.filters,
    itemCategories: state => state.itemCategories,
    itemMenus: state => state.itemMenus,
  },
  mutations: {
    SAVE_ITEMS(state, items) {
      state.items = items
    },
    SAVE_ITEM(state, item) {
      state.item = item
    },
    SAVE_ITEMS_CATEGORIES(state, items) {
      state.itemCategories = items
    },
    SAVE_ITEMS_MENUS(state, items) {
      state.itemMenus = items
    },
    SAVE_ITEM_LABELS(state, itemLabels) {
      state.itemLabels = itemLabels
    },
    SAVE_ITEMS_TOTAL(state, itemsTotal) {
      state.itemsTotal = itemsTotal
    },
    SAVE_ITEM_TOTAL(state, itemTotal) {
      state.itemTotal = itemTotal
    },
    CLEAR_ITEMS(state) {
      state.items = []
    },
    CLEAR_ITEM(state) {
      state.item = {}
      state.itemTotal = 0
    },
  },
  actions: {
    async fetch({ commit }) {
      try {
        const resp = await Vue.prototype.$http.get(`${Vue.prototype.$groupUrl}/section-labels`)
        commit('SAVE_ITEMS_TOTAL', resp.data.total)
        commit('SAVE_ITEMS', resp.data.data.data)
      } catch (e) {
        commit('CLEAR_ITEMS')
      }
    },
    async fetchSection({ commit }, section) {
      try {
        const resp = await Vue.prototype.$http.get(`${Vue.prototype.$groupUrl}/section-labels/${section}`)
        commit('SAVE_ITEM', resp.data.data)
      } catch (e) {
        console.log(e)
      }
    },
    async fetchCategories({ commit }, menu = null) {
      try {
        const menuUrl = menu ? `/${menu}` : ''
        const resp = await Vue.prototype.$http.get(`${Vue.prototype.$groupUrl}/section-labels/getSections${menuUrl}`)
        commit('SAVE_ITEMS_CATEGORIES', resp.data.data.data)
      } catch (e) {
        console.log(e)
      }
    },
    async fetchMenus({ commit }) {
      try {
        const resp = await Vue.prototype.$http.get(`${Vue.prototype.$groupUrl}/section-labels/allMenus`)
        commit('SAVE_ITEMS_MENUS', resp.data.data)
      } catch (e) {
        console.log(e)
      }
    },
    async fetchLabels({ commit }, section) {
      // GET LABELS TO SHOW ON SECTION
      try {
        const resp = await Vue.prototype.$http.get(`${Vue.prototype.$groupUrl}/section-labels/showLabel/${section}`)
        commit('SAVE_ITEM_LABELS', resp.data.data)
      } catch (e) {
        console.log(e)
      }
    },
    async update({ commit }, data) {
      try {
        const resp = await Vue.prototype.$http.put(`${Vue.prototype.$groupUrl}/section-labels/update/${data.section}`, data.data)
        commit('SAVE_ITEM', resp.data.data)
        return resp
      } catch (e) {
        console.log(e)
        throw e
      }
    },
    async updateItem({ commit }, data) {
      try {
        await Vue.prototype.$http.put(`${Vue.prototype.$groupUrl}/section-labels/update-label/${data.data.id}`, data.data)
      } catch (e) {
        console.log(e)
      }
    },
    async filterSearch({ commit }, data) {
      try {
        const resp = await Vue.prototype.$http.post(`${Vue.prototype.$groupUrl}/section-labels/search-filter`, data)
        commit('SAVE_ITEM', resp.data.data)
        commit('SAVE_ITEM_TOTAL', resp.data.total)
      } catch (e) {
        commit('CLEAR_ITEM')
      }
    },
    async filterIndex({ commit }, filters) {
      try {
        const resp = await Vue.prototype.$http.post(`${Vue.prototype.$groupUrl}/section-labels/filter`, filters)
        commit('SAVE_ITEMS', resp.data.data.data)
        commit('SAVE_ITEMS_TOTAL', resp.data.total)
      } catch (e) {
        commit('CLEAR_ITEMS')
      }
    },
    clearItem({ commit }) {
      commit('CLEAR_ITEM')
    },
  },
}
