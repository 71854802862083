import Vue from 'vue'
import Router from 'vue-router'
import { ToastPlugin, ModalPlugin, VBToggle } from 'bootstrap-vue'
import VueCompositionAPI from '@vue/composition-api'
import VueQuill from 'vue-quill-editor'
import VueSweetalert2 from 'vue-sweetalert2'
import VueToastr2 from 'vue-toastr-2'
import 'vue-toastr-2/dist/vue-toastr-2.min.css'
import ImageResize from 'quill-image-resize'
import { ImageDrop } from 'quill-image-drop-module'
import htmlEditButton from 'quill-html-edit-button'
import DatePicker from 'vue2-datepicker'
import permissionsMixin from './mixins/permissions'
import {
  getDay, getMonth, getYear, compareDates,
} from './services/serviceDates'

import router from './router'
import store from './store'
import App from './App.vue'

// Global Components
import './global-components'

// 3rd party plugins
import '@/libs/portal-vue'
import '@/libs/toastification'

window.toastr = require('toastr')
window.moment = require('moment')

const UploadFile = {
  install() {
    Vue.prototype.$saveFiles = data => {
      if (data && data.length > 0) {
        const formData = new FormData()

        data.forEach((element, index) => {
          if (element && element.file) {
            formData.append(`files-${index}`, element.file)
          }
        })

        Vue.prototype.$http.post(`${Vue.prototype.$groupUrl}/files/save-file`, formData, {
          headers: {
            'Content-Type': 'multipart/form-data',
          },
        })
      }
    }
  },
}

const Export = {
  install() {
    Vue.prototype.$export = data => {
      Vue.prototype.$http.post(data.url, data.filters, {
        responseType: 'arraybuffer',
      }).then(response => {
        store.dispatch('modals/toggleExportLoading', false)

        if (data.background) {
          if (response.status == 200) {
            Vue.swal('Export started', 'You will receive an email when its done', 'success')
          } else {
            Vue.swal('Export failed', 'Please, contact to the administrator', 'error')
          }
        } else {
          // Create a Blob from the received response data
          const blobData = new Blob([response.data], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' })

          // Create a URL for the blob data
          const blobUrl = window.URL.createObjectURL(blobData)

          // Create a link and initiate the download
          const link = document.createElement('a')
          link.href = blobUrl
          link.download = `${data.name}.xlsx`
          link.click()
        }
      }).catch(() => {
        store.dispatch('modals/toggleExportLoading', false)
        Vue.swal('Export failed', 'Please, contact to the administrator', 'error')
      })
    }
  },
}

const { Quill } = VueQuill
Quill.prototype.getHtml = e => e.container.firstChild.innerHTML
const Block = Quill.import('blots/block')
Block.tagName = 'div'
Quill.register(Block)

// Resize image module
Quill.register('modules/imageResize', ImageResize)

// Image drop module
Quill.register('modules/imageDrop', ImageDrop)

// Show html button
Quill.register('modules/htmlEditButton', htmlEditButton)

Vue.use(UploadFile)
Vue.use(Export)
// BSV Plugin Registration
Vue.use(ToastPlugin)
Vue.use(VueToastr2)
Vue.use(ModalPlugin)

Vue.use(DatePicker)

Vue.use(getDay)
Vue.use(getMonth)
Vue.use(getYear)
Vue.use(compareDates)
Vue.mixin(permissionsMixin)
Vue.use(VueQuill, {
  modules: {
    clipboard: {
      matchVisual: false, // Reté el format al màxim
    },
    htmlEditButton: {},
    toolbar: [
      ['bold', 'italic', 'underline', 'strike'], // toggled buttons
      [{ header: 1 }, { header: 2 }, { header: 3 }],
      ['blockquote', 'code-block'],
      ['link', 'image', 'video', 'formula'],
      [{ list: 'ordered' }, { list: 'bullet' }, { list: 'check' }],
      [{ script: 'sub' }, { script: 'super' }], // superscript/subscript
      [{ indent: '-1' }, { indent: '+1' }], // outdent/indent
      [{ direction: 'rtl' }], // text direction
      [{ size: ['small', false, 'large', 'huge'] }], // custom dropdown
      [{ header: [1, 2, 3, 4, 5, 6, false] }],
      [{ color: [] }, { background: [] }], // dropdown with defaults from theme
      [{ font: [] }],
      [{ align: [] }],
      ['clean'],
    ],
    imageResize: {
      displayStyles: {
        backgroundColor: 'black',
        border: 'none',
        color: 'white',
      },
      modules: ['Resize', 'DisplaySize', 'Toolbar'],
    },
  },
})
Vue.use(VueSweetalert2)
Vue.directive('b-toggle', VBToggle)
// Composition API
Vue.use(VueCompositionAPI)

Vue.config.productionTip = false

// Create a copy of the original resolve function
const originalResolve = Router.prototype.resolve

// Parameters to preserve from current route to the new route
const PRESERVE_PARAMS = ['actAs', 'prefilterUser']

// Override the resolve function
Router.prototype.resolve = function (to, current, append) {
  // Get the current route from the router instance if not provided
  const currentRoute = current || this.currentRoute

  // First get the resolved route using the original resolve method
  const resolved = originalResolve.call(this, to, current, append)

  // Only proceed if we have a current route with query parameters
  if (currentRoute && currentRoute.query) {
    // Create a new location object based on the resolved one
    const newLocation = {
      ...resolved.location,
    }

    // Initialize query if it doesn't exist
    if (!newLocation.query) {
      newLocation.query = {}
    }

    // Check each parameter that should be preserved
    let shouldReResolve = false

    PRESERVE_PARAMS.forEach(param => {
      // Only copy the parameter if it exists in current route and doesn't exist in target route
      if (currentRoute.query[param] && !newLocation.query[param]) {
        newLocation.query[param] = currentRoute.query[param]
        shouldReResolve = true
      }
    })

    // Only re-resolve if we actually added parameters
    if (shouldReResolve) {
      // Re-resolve with the updated query to get the correct href
      return originalResolve.call(this, newLocation, current, append)
    }
  }

  return resolved
}

new Vue({
  router,
  store,
  render: h => h(App),
}).$mount('#app')
